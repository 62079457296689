import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import ValueProps from "../components/Repeating/ValueProps";
import About from "../components/Repeating/About";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = [
    {
      question: "Should I refinance?",
      answer: (
        <>
          <p>
            When mortgage rates drop 1% to 2% lower than your current loan rate,
            it’s an excellent time to consider refinancing. The most significant
            benefit is that your monthly mortgage payment is likely to drop,
            saving you money in your budget.
          </p>
        </>
      ),
    },
    {
      question: "What are points?",
      answer: (
        <>
          <p>
            You can pay a lender points to get specific terms you'd like in your
            mortgage financing. Each point is 1% of your total loan. For
            example, if your home loan amount were $400,000, each point would
            cost $4,000. Points are typically purchased to get a lower interest
            rate.
          </p>
        </>
      ),
    },
    {
      question: "Should I pay points to lower my interest rate?",
      answer: (
        <>
          <p>
            If you plan to stay in your home for at least a few years, then
            paying for points to lower the interest rate can help you keep your
            monthly loan payment where you want it. Points can also help you
            qualify for a bigger loan amount.
          </p>
          <p>
            If you don’t plan on living in the home for more than a year or two,
            it’s probably best not to consider purchasing points. That’s because
            your monthly savings may not make up the cost you paid upfront.
          </p>
        </>
      ),
    },
    {
      question: "What is an APR?",
      answer: (
        <>
          <p>
            APR refers to the “annual percentage rate.” It’s the cost of a
            mortgage on an annual basis and is likely to be higher than the
            stated note rate because it also takes into account points and other
            credit costs.{" "}
          </p>
          <p>
            The value of knowing the APR is that it allows home buyers to
            compare different types of mortgages based on the loan’s annual
            cost. In other words, it measures the true cost of a loan. It’s also
            important to note that it doesn’t affect your monthly mortgage
            payments.
          </p>
        </>
      ),
    },
    {
      question: "What does it mean to lock the interest rate?",
      answer: (
        <>
          <p>
            Interest rates can change from day to day, and locking in your rate
            keeps you from having to ride the wave. A sharp rise in an interest
            rate can increase a mortgage payment, so lenders can allow a
            borrower to lock in an interest rate. The rate is locked for a
            specific period of time, usually 30 to 60 days, and sometimes there
            is a fee.
          </p>
        </>
      ),
    },
    {
      question: "What documents do I need to prepare for my loan application?",
      answer: (
        <>
          <p>
            Preparing your documentation in advance can help ensure your loan
            application gets off on the right foot and will help speed up the
            process. Typically, you’ll need to provide copies of these items:
          </p>
          <ul className="pl-6 list-disc mb-6">
            <li>Photo ID</li>
            <li>Pay stubs</li>
            <li>W-2s</li>
            <li>Bank statements</li>
            <li>Documentation for any other assets you have</li>
            <li>
              A gift letter if a family member or friend is giving you money to
              help with your home purchase
            </li>
          </ul>

          <p>
            Lenders will also pull your credit report, so taking the time to
            review it in advance is a good idea. You’ll want to be prepared to
            explain any blemishes on your report, for example. To get started,
            visit{" "}
            <a
              href="https://www.annualcreditreport.com/index.action"
              target="_blank"
              rel="noreferrer"
            >
              annualcreditreport.com
            </a>{" "}
            to request your credit report from the three major reporting
            companies—Equifax, Experian, and TransUnion. You’re entitled to a
            free report from these companies every 12 months.
          </p>
        </>
      ),
    },
    {
      question: "How is my credit judged by lenders?",
      answer: (
        <>
          <p>
            Lenders use a credit report to get a complete picture of your
            finances and ensure you are a responsible borrower. They’ll look at
            your credit report for indications of your bill-paying history, any
            late payments or collections that may be on your report, the number
            of accounts (and type of accounts) you have, and the age of your
            accounts.
          </p>
        </>
      ),
    },
    {
      question: "What can I do to improve my credit score?",
      answer: (
        <>
          <p>
            A good credit score can certainly help you get favorable mortgage
            terms. However, if your score is low, here are three steps you can
            take to begin improving it:
          </p>
          <ul className="pl-6 list-disc mb-6">
            <li>Pay your bills on time. </li>
            <li>
              Are you close to the credit limits on your credit cards? Pay these
              down.
            </li>
            <li>
              Don’t apply for new credit, especially while trying to get a home
              loan. Your score may drop if you have too many credit inquiries.
            </li>
          </ul>

          <p>
            Keep in mind that it can take time to improve a credit score. But if
            you focus your efforts correctly, you’ll see a change moving
            forward.
          </p>
        </>
      ),
    },
    {
      question: "What is an appraisal?",
      answer: (
        <>
          <p>
            Appraisals are key to mortgage loans because they give lenders an
            estimate of a property’s fair market value. Only a state-licensed
            professional is qualified to appraise a property. They inspect the
            physical condition of the property and take into account its
            location and comparable properties in the area and other factors.
          </p>
        </>
      ),
    },
    {
      question: "What is PMI (Private Mortgage Insurance)?",
      answer: (
        <>
          <p>
            Lenders use Private Mortgage Insurance (PMI) to protect themselves
            if you can't pay your mortgage and end up defaulting. You'll
            typically be required to purchase PMI if your down payment is less
            than 20%.
          </p>
        </>
      ),
    },
    {
      question: "What is 80-10-10 financing?",
      answer: (
        <>
          <p>
            Home buyers who don’t have 20% cash down and want to avoid paying
            for Private Mortgage Insurance have an option called 80-10-10
            financing, which works like this:
          </p>

          <ul className="pl-6 list-disc mb-6">
            <li>
              You have a traditional first mortgage for 80% of the home’s
              purchase price
            </li>
            <li>You have a second mortgage for 10%</li>
            <li>And then you make a cash down payment of 10</li>
          </ul>

          <p>
            There’s also 80-15-5 financing, although you may have a higher
            interest rate and have to pay higher loan fees because lenders view
            this as riskier (due to the low down payment).
          </p>
        </>
      ),
    },
    {
      question: "What happens at closing?",
      answer: (
        <>
          <p>Closing is when you officially take ownership of the property. </p>
          <p>
            Just before closing, you’ll be invited to take a walk-through of
            your home to ensure that any requested repairs have been completed
            and that any items it was agreed would stay in the house (such as
            appliances) are included.
          </p>
          <p>
            You’ll sign the final paperwork, deliver your closing funds
            (typically via a cashier’s check or wire transfer), and get your
            keys.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="First-Time Home Loans in Minnesota | Thistle Financial"
        description="Navigate your first-time loan with Thistle Financial by your side, and before you know it, you'll have the keys to your new home."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        padding="pb-16 md:pb-0"
      >
        <h1>First-Time Home Loans In Minnesota</h1>
        <p>
          Getting the keys to your first home is a special moment一it’s a
          wonderful feeling when it’s finally yours. At Thistle Financial,
          there’s nothing we love more than to help our fellow Minnesotans with
          their first home loan.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="mb-20 md:mb-32 bg-primary-50 py-20 md:py-32">
        <div className="container">
          <header className="max-w-2xl mb-16 md:mb-20">
            <h2>What Are The Best Loans For First-Time Home Buyers?</h2>
          </header>

          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-20">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4 hidden md:block relative after:content after:absolute after:right-0 after:top-0 after:w-px after:h-full after:bg-primary-600/30">
              <li>
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="FHA Home Loan"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="VA Home Loan"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Fannie Mae & Freddie Mac"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-4"}
                  title="FHA 203(k)"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-5"}
                  title="Good Neighbor Next Door"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-6"}
                  title="Local Government Programs"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <div
                id="anchor-1"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">FHA Home Loan</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  An FHA loan is one that’s guaranteed by the government and is
                  typically a good option for first-time home buyers or those
                  with a spotty credit history.
                </p>

                <h4>Benefits</h4>
                <ul className="list-check mb-6">
                  <li>Smaller down payment</li>
                  <li>Lower interest rates</li>
                  <li>Easier on those with weaker credit</li>
                  <li>100% of down payment can be a gift</li>
                </ul>
              </div>

              <div
                id="anchor-2"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">VA Home Loan</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Active service members, veterans, and their surviving spouses
                  can purchase a home with a VA loan, which is offered by the
                  U.S. Department of Veterans Affairs. To qualify, the VA will
                  verify military status.
                </p>

                <h4>Benefits</h4>
                <ul className="list-check mb-6">
                  <li>No down payment</li>
                  <li>No minimum credit score</li>
                  <li>Private Mortgage Insurance (PMI) is not required</li>
                  <li>Pay off loan at any time with no penalty</li>
                </ul>
              </div>

              <div
                id="anchor-3"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Fannie Mae and Freddie Mac Loans</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Fannie Mae and Freddie Mac loans are home mortgage companies
                  that are federally sanctioned to work with local lenders. They
                  do not originate loans or service them, but instead buy and
                  guarantee them.
                </p>

                <h4>Benefits</h4>
                <ul className="list-check mb-6">
                  <li>Low down payment</li>
                  <li>Competitive interest rates</li>
                  <li>Assistance for low to moderate-income buyers</li>
                  <li>Down payment assistance programs</li>
                </ul>
              </div>

              <div
                id="anchor-4"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">FHA 203(k) Home Loans </h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  An FHA 203(k) is a loan designed for borrowers who are
                  interested in purchasing a fixer-upper. You borrow money for
                  the purchase price, plus an additional about to complete an
                  improvement project.
                </p>

                <h4>Benefits</h4>
                <ul className="list-check mb-6">
                  <li>Low down payment</li>
                  <li>
                    You can finance the purchase price + extra funds for
                    remodeling
                  </li>
                  <li>You build equity fast</li>
                  <li>
                    Both adjustable-rate and fixed-rate mortgages available
                  </li>
                </ul>
              </div>

              <div
                id="anchor-5"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Good Neighbor Next Door</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Law enforcement, emergency medical technicians, and
                  firefighters have an option to purchase a home with the Good
                  Neighbor Next Door program, sponsored by the U.S. Department
                  of Housing and Urban Development. The program offers a 50%
                  discount on homes in areas that are being revitalized.
                </p>

                <h4>Benefits</h4>
                <ul className="list-check mb-6">
                  <li>1/2 off the list price of an eligible home</li>
                  <li>You’ll be a part of revitalizing a neighborhood</li>
                </ul>
              </div>

              <div
                id="anchor-6"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-10 md:pb-12"
              >
                <h3 className="mb-1">
                  You’ll be a part of revitalizing a neighborhood
                </h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Many local governments offer programs for first-time home
                  buyers. The programs and availability may vary, so it's best
                  to <a data-modal-open="modal-contact">contact us</a> for the
                  most recent information on these types of loans.
                </p>

                <h4>Benefits Vary And May Include</h4>
                <ul className="list-check mb-6">
                  <li>Low interest rates</li>
                  <li>Program for low-income buyers</li>
                  <li>Down payment assistance</li>
                  <li>Closing cost assistance</li>
                </ul>
              </div>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h2>Frequently Asked Questions</h2>
          </header>

          <Accordion
            allowZeroExpanded={true}
            className="border-t border-solid border-primary-600/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    className="border-b border-solid border-primary-600/20 pt-6 md:pt-7 pb-5 md:pb-6"
                    uuid={i}
                  >
                    <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                      <p className="font-heading text-xl md:text-3xl font-bold mb-0">
                        {faq.question}
                      </p>
                      <AccordionItemState>
                        {(state) => {
                          const icon = state.expanded
                            ? "fa-chevron-up"
                            : "fa-chevron-down";
                          return (
                            <i
                              className={`far ${icon} text-lg text-primary-400 ml-4`}
                            ></i>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 max-w-4xl animate-fadeIn">
                      <p className="mb-0">{faq.answer}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Testimonials />
      <Resources />
      <ValueProps />
      <About className="mb-0" />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Fist Time Buyers.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Fist Time Buyers.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: { eq: "2.1 First-Time buyers/1.0 hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
  }
`;
export default Page;
